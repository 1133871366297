import { render, staticRenderFns } from "./Lgx171234567890123456.vue?vue&type=template&id=f7b8c1f4&"
import script from "./Lgx171234567890123456.vue?vue&type=script&lang=js&"
export * from "./Lgx171234567890123456.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports